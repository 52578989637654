import { defineStore } from 'pinia';
import BlobToBase64 from "~/packages/core/actions/conversion/BlobToBase64";

export const useRecordingStore = defineStore({
  id: 'recording',
  state: () => ({
    base64Recording: null, // Store the Base64 string for the recording
  }),
  actions: {
    async saveRecording(blob) {
      this.base64Recording = await BlobToBase64.execute(blob);
    },
    clearRecording() {
      this.base64Recording = null;
    }
  },
  persist: {
    storage: localStorage,
  },
});
